import React from 'react';
import {useParams} from 'react-router-dom';
import apiService from '../common/apiService';
import ApiError from '../component/ApiError';
import Venue from '../types/Venue';
import VenueCard from '../component/VenueCard';
import {Box, Chip, Typography} from '@mui/material';
import Promotion from '../types/Promotion';
import PromotionCard from '../component/PromotionCard';
import useWindowDimensions from '../common/useWindowDimensions';

interface MobileViewProps {
    venue: Venue;
    width: number;
}

function MobileView({venue, width}: MobileViewProps) {
    return (
        <Box sx={{p: 1}}>
            <Box
                sx={{height: 'auto', width: width - 16}}
                component="img" src="/images/placeholder.png" />
            <Typography variant="h6">{venue.name}</Typography>
            <Typography variant="body2">{venue.description}</Typography>
            <Typography variant="body2">{venue.about}</Typography>
            <Typography variant="body2">{venue.address + ', ' + venue.city + ', ' + venue.country}</Typography>
            <Chip size="small" label={venue.category} />
        </Box>
    );
}

export default function VenuePage() {
    const { width } = useWindowDimensions();
    const params = useParams();
    if (!params.id) {
        return <div>not found</div>;
    }

    const response = apiService<Venue>('venue/' + params.id);
    const promotionsResponse = apiService<Promotion[]>('venue/' + params.id + '/promotions');

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (promotionsResponse.error) {
        return <ApiError error={promotionsResponse.error}/>;
    }

    if (response.isLoading || promotionsResponse.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {width < 700
                ? <MobileView venue={response.result} width={width} />
                : <VenueCard venue={response.result} />}
            <Typography sx={{p: 1}} variant="h5">Promotions:</Typography>
            {promotionsResponse.result.map((el, i) => <PromotionCard shallow key={i} promotion={el} />)}
        </div>
    );
}
